import './App.css';
import './navbar.css';
import './navbar2.css';
import './hero.css';
import './aboutUs.css';
import './services.css';
import './gallery.css';
import './doctors.css';
import './footer.css';
import './sidebar.css';
import './testimonials.css';

import { ElfsightWidget } from 'react-elfsight-widget';
import { SocialIcon } from 'react-social-icons';


import logo from './images/DS_Logo.jpg';
import hero from './images/DS_Hero.png';
import Hamburger from 'hamburger-react';

import aestheticIcon from './images/service icons/aesthetic.png';
import clearAlignerIcon from './images/service icons/clear_aligner.png';
import crownBridgeIcon from './images/service icons/crown_bridge.png';
import fixedOrthoIcon from './images/service icons/fixed_ortho.png';
import implantIcon from './images/service icons/implant.png';
import iopaIcon from './images/service icons/iopa.png';
import jewelIcon from './images/service icons/jewel.png';
import laserIcon from './images/service icons/laser_dentistry.png';
import maxilloIcon from './images/service icons/maxillo.png';
import oralIcon from './images/service icons/oral_surgery.png';
import pediatricIcon from './images/service icons/pediatric.png';
import rootIcon from './images/service icons/root_canal.png';
import scalingIcon from './images/service icons/scaling.png';
import cosmeticIcon from './images/service icons/cosmetic.png';

import g1 from './images/gallery/g1.jpg';
import g2 from './images/gallery/g2.jpeg';
import g3 from './images/gallery/g3.jpeg';
import g4 from './images/gallery/g4.jpeg';

import g5 from './images/gallery/g5.jpeg';
import g6 from './images/gallery/g6.jpeg';

import d1 from './images/Doctors/d1.jpeg';
import d2 from './images/Doctors/d2.jpeg';
// import dT from './images/Doctors/dtemplate.jpg';

import {useState} from 'react';

function Service({sIcon, sHeading, sDesc}){
  return(
    <div className='serviceComponent'>
      <img className='serviceIcon' src={sIcon}></img>
      <div className='serviceHeading'>{sHeading}</div>
      {/* <div className='serviceDesc'>{sDesc}</div> */}
    </div>
  )
}

function Doctor({dPic, dName, dQualif, dDesc}){
  return(
    <div className='doctorComponent'>
      <img className='doctorPic' src={dPic}></img>
      <div className='doctorName'>{dName}</div>
      <div className='doctorQualif'>{dQualif}</div>
      <div className='doctorDesc'>{dDesc}</div>
    </div>
  )
}



function App() {
  const [isShown, setIsShown] = useState(false);

  const HamburgerButton = event => {
    // 👇️ toggle visibility
    setIsShown(current => !current);
  };

  return(
    <div className="App">

      <div className='navbar2'>
        <a href="#"><img className='logo2' src={logo}/></a>
        <div className='navlinkContainer2'>
          <a href="#aboutUs" className='navlink2'>About Us</a>
          <a href="#services"  className='navlink2'>Services</a>
          <a href="#gallery" className='navlink2'>Gallery</a>
          <a href="#doctorSection" className='navlink2'>Doctors</a>
          <a href="#testimonials" className='navlink2'>Testimonials</a>
          <a href="#footer" className='navlink2'>Contact Us</a>
        </div>
        <button onClick={HamburgerButton} className='hamburgerIcon2'><Hamburger/></button>
      </div>

      <div 
      className={ isShown ? 'sideBar' : 'sideBar2'}
      id='sideBar'>
        <div className='sidelinkContainer'>
          <a href="#aboutUs" className='sidelink2'>About Us</a>
          <a href="#services"  className='sidelink2'>Services</a>
          <a href="#gallery" className='sidelink2'>Gallery</a>
          <a href="#doctorSection" className='sidelink2'>Doctors</a>
          <a href="#testimonials" className='sidelink2'>Testimonials</a>
          <a href="#footer" className='sidelink2'>Contact Us</a>
        </div>
      </div>


      <div className='HS'>
        <div className='heroSection'>
          <div className='heroSlogan'>
            <div className='sloganSub1'>Welcome To </div>
            <div>Dental Solutions</div>
            <div className='sloganSub3'>Specialist's Dental Clinic</div>
            <div className='sloganSub2'>We value our patients highly, 
                  and always ensure that the best dental treatment has been provided
                   for your Oral Health</div>
            <a target="_blank" href="https://registration.hypha.care/register?o=63&b=63"><button className='appointmentButton'>Request An Appointment</button></a>
            <a target="_blank" href="tel:+91-91881-54587"><button className='appointmentButton'>Call Now</button></a>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=9495862852&text="><button className='appointmentButton'>Whatsapp</button></a>
          </div>
          <div className='heroPicContainer'>
            <img className='heroPic' src={hero}/>
          </div>
        </div>
      </div>


      <div className='aboutUs' id='aboutUs'>
        <div className='aboutUsHeading'>About us</div>
        <div className='aboutUsDesc'>In Dental Solutions Dental Clinic, we are committed to providing our patients with genuine care and excellent
         dental treatment at all times. Your smile and dental health is our number one priority. Our practice started with understanding the nature
          of human fear towards dentistry, the complexity of dental treatment and bringing in years of experience for each client we treat in our clinic.<br/><br/>
We provide personalised treatment to each patient. We understand that each patient has unique and specific dental health care needs. So each patient that 
visit our clinic undergoes customised treatment for achieving healthier teeth,gums and a beautiful smile. All doctors in our clinic are highly experienced,
 well trained and accommodating. Equipped with a rich knowledge of current technology and the latest research in dentistry, we provide one on one dental service
  to each patient's unique concerns..<br/><br/> At Dental Solutions Dental Clinic, we care about your comfort and convenience. From the moment you open our clinic doors, 
  you will be welcomed by our friendly staff. We provide you a relaxing and positive atmosphere with a gentle and personalised touch which you deserve.
Everyday we strive to make your experience a positive one by providing you a relaxing atmosphere and rendering high quality cost effective dental treatments. 
Our rigid quality control measures will be used to monitor the care we offer and our highly developed systems are to help every patient.
If you are looking for the best clinic in Calicut, Dental Solutions Dental Clinic is your top choice. Schedule an appointment now and experience a dental health 
care service like no other.
            </div>
      </div>


      <div className='services' id='services'>
        <div className='serviceHeader'>Services</div>
        <div className='serviceComponentsFlexbox'>
            <Service sIcon={fixedOrthoIcon} sHeading={"FIXED ORTHODONTICS"}></Service>
            <Service sIcon={clearAlignerIcon} sHeading={"CLEAR ALIGNERS"}></Service>
            <Service sIcon={rootIcon} sHeading={"ROOT CANAL"}></Service>
            <Service sIcon={implantIcon} sHeading={"DENTAL IMPLANTS"}></Service>
            <Service sIcon={aestheticIcon} sHeading={"AESTHETIC DENTISTRY"}></Service>
            <Service sIcon={cosmeticIcon} sHeading={"COSMETIC DENTISTRY"}></Service>
            <Service sIcon={pediatricIcon} sHeading={"PEDIATRIC DENTISTRY"}></Service>
            <Service sIcon={crownBridgeIcon} sHeading={"CROWNS AND BRIDGES"}></Service>
            <Service sIcon={iopaIcon} sHeading={"DENTAL RADIOGRAPHS"}></Service>
            <Service sIcon={oralIcon} sHeading={"ORAL MINOR SURGERY"}></Service>
            <Service sIcon={maxilloIcon} sHeading={"MAXILLOFACIAL MINOR SURGERY"}></Service>
            <Service sIcon={scalingIcon} sHeading={"SCALING"}></Service>
            <Service sIcon={jewelIcon} sHeading={"TEETH JEWEL"}></Service>
        </div>
      </div> 



      <div className='gallery' id='gallery'>
        <div className='galleryHeader'>Gallery</div>
        <div className='galleryFlexbox'>
          <img className="galleryPic" src={g5}/>
          <img className="galleryPic" src={g6}/>
          <img className="galleryPic" src={g1}/>
          <img className="galleryPic" src={g2}/>
          <img className="galleryPic" src={g3}/>
          <img className="galleryPic" src={g4}/>
        </div>
      </div>


      <div className='doctorSection' id='doctorSection'>
        <div className='doctorHeader'>Meet our Team</div>
        <div className='doctorFlexbox'>
          {/* <Doctor dPic={d1} dName={"Dr. Remya Ramachandran"} dQualif={"BDS.MDS"} dDesc={"Chief Dental Surgeon, Department of Pediatric Dentistry"}/> */}
          <Doctor dPic={d1} dName={"Dr. Dhanya Menon M"} dQualif={"BDS, MDS"} dDesc={"Department of Orthodontics"}/>
          <Doctor dPic={d2} dName={"Dr. Sunith M"} dQualif={"BDS, MDS"} dDesc={"Department of Endodontics"}/>
          {/* <Doctor dPic={d2} dName={"Dr. Fazmiya"} dQualif={"BDS.MDS"} dDesc={"Department of Oral and Maxillofacial Surgery"}/>
          <Doctor dPic={dT} dName={"Dr. Manu Mathew"} dQualif={"BDS.MDS"} dDesc={"Department of Oral and Maxillofacial Surgery"}/>
          <Doctor dPic={dT} dName={"Dr. Aparna T.K."} dQualif={"BDS.MDS"} dDesc={"Department of Periodontics"}/>
          <Doctor dPic={dT} dName={"Dr. Anju Ramachandran"} dQualif={"BDS.MDS"} dDesc={"Department of Orthodontics"}/>
          <Doctor dPic={dT} dName={"Dr. Nimil U"} dQualif={"BDS.MDS"} dDesc={"Department of Orthodontics"}/>
          <Doctor dPic={dT} dName={"Dr. Safir"} dQualif={"BDS.MDS"} dDesc={"Department of Orthodontics"}/>
          <Doctor dPic={dT} dName={"Dr. Makal Madhav"} dQualif={"BDS.MDS"} dDesc={"Department of Orthodontics"}/> */}
        </div>
      </div>

      <div id="testimonials">
        <div className='testimonialHeader'>Testimonials</div>  
        <ElfsightWidget className='testimonial' widgetID="04330198-8582-489d-a5fe-2c1ea61ae72b" />
      </div>

      <footer className='footer' id='footer'>
        <div className='footerHeading'>Contact us</div>
        <div className='contactDetails'>
        <SocialIcon target="_blank" url="https://api.whatsapp.com/send?phone=9495862852&text=" />&nbsp;&nbsp;
        <SocialIcon target="_blank" url="https://www.instagram.com/dentalsolutionsclt/?hl=en" />&nbsp;&nbsp;
        <SocialIcon target="_blank" url="https://www.facebook.com/dentalsolutionsclt/" /><br/>
          Mobile : <a className="mobile_a_tag" href="tel:+91-91881-54587">+919188154587</a> &nbsp;, <a className="mobile_a_tag" href="tel:+91-94958-62852">+919495862852</a><br/>
          Email ID : mdmenondhyan@gmail.com<br/>
          Address : 1st Floor, Galeela Tower, Meenchanda, Kozhikode - 673018 (Bypass Junction)<br/><br/>
          <u>WORKING HOURS</u><br/>
          Monday to Saturday : 10:30 AM to 6:30 PM<br/>
          {/* Saturday : 4 PM to 7 PM<br/> */}
          Sunday : CLOSED
        </div>
        <iframe className="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8115.035650204994!2d75.80542001680368!3d11.21367706226783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65988a6bb1d57%3A0x84ef18163362e942!2sDental%20Solutions%20Dental%20Clinic!5e0!3m2!1sen!2sin!4v1680071780036!5m2!1sen!2sin" height="450"></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8115.035650204994!2d75.80542001680368!3d11.21367706226783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65988a6bb1d57%3A0x84ef18163362e942!2sDental%20Solutions%20Dental%20Clinic!5e0!3m2!1sen!2sin!4v1680071780036!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <div className='copyright'>© 2023, Fraktal Ecosystems Pvt. Ltd. All Rights Reserved.</div>
      </footer>

    </div>
  );
}

export default App;
